@import './components/blog-entries/index.css';

.logo-header {
  display: grid;
  justify-items: center;
  margin-bottom: 1.25rem;
}

.logo-header h1 {
  margin: 0px;
}

.login-form {
  display: grid;
  justify-content: center;
}

.login-form fieldset {
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
}

.login-form .button-cluster {
  display: grid;
}
